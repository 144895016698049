import { Button, Container } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { educationExpenseTypeOptions } from '../../../data/education-expense';
import { useCreateInput } from '../../../hooks/useCreateInput';
import { EEducationExpenseStatus } from '../../../interfaces/domain';
import { IAppState } from '../../../interfaces/state';
import { ISearchState } from '../../../interfaces/state/ISearchState';
import { getFormData, IControls, IFormData } from '../../../shared/utility';
import CustomDialog from '../../UI/CustomDialog/CustomDialog';
import { EInputType } from '../../UI/Input/Input';
import classes from './EducationExpenseSearch.module.scss';

// created, user, status

const statusOptions = [
  { value: EEducationExpenseStatus.NEW, text: "Uusi" },
  { value: EEducationExpenseStatus.SENT, text: "Lähetetty" },
  { value: EEducationExpenseStatus.CONFIRMED, text: "Vahvistettu" },
  { value: EEducationExpenseStatus.SENT_TO_NV, text: "Valmis" },
  { value: EEducationExpenseStatus.SEND_TO_NV_FAILED, text: "Virhe" },
];

interface IProps {
  isAdmin: boolean;
  onSearch: (data: IFormData) => void;
  onSendToNetvisor: () => void;
  disableNetvisor: boolean;
}

export enum EFields {
  statuses = "statuses",
  createdStart = "createdStart",
  createdEnd = "createdEnd",
  users = "users",
  type = "type",
}

const initControls: IControls = {
  controls: {
    [EFields.statuses]: {
      type: EInputType.select,
      value: [],
      label: "Valitse tila",
      options: statusOptions,
      multiple: true,
      valid: true,
    },
    [EFields.createdStart]: {
      type: EInputType.date,
      value: moment().dayOfYear(1),
      label: "Luonti alku",
      valid: true,
    },
    [EFields.createdEnd]: {
      type: EInputType.date,
      value: null,
      label: "Luonti loppu",
      valid: true,
    },
    [EFields.users]: {
      type: EInputType.select,
      value: [],
      label: "Valitse käyttäjät",
      options: [],
      multiple: true,
      valid: true,
    },
    [EFields.type]: {
      type: EInputType.select,
      value: "",
      label: "Valitse tyyppi",
      options: educationExpenseTypeOptions,
      valid: true,
    },
  },
  isValid: false,
};

const EducationExpensesSearch: React.FC<IProps> = ({
  isAdmin,
  onSearch,
  onSendToNetvisor,
  disableNetvisor,
}) => {

  const [controls, setControls] = useState(initControls);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const { users } = useSelector<IAppState, ISearchState>(
    (state) => state.search
  );

  const createInput = useCreateInput(controls, setControls);

  const searchHandler = () => {
    const data = getFormData(controls.controls) as IFormData;
    onSearch(data);
  };

  useEffect(() => {
    onSearch(getFormData(initControls.controls));
  }, [onSearch]);

  const sendToNetvisorHandler = () => {
    onSendToNetvisor();
    setConfirmOpen(false);
  };

  return (
    <Container>
      {isAdmin && (
        <CustomDialog
          title="Vie Netvisoriin"
          content="Haluatko varmasti lähettää luentopalkkiot Netvisoriin?"
          buttonText="Lähetä"
          onCancel={() => setConfirmOpen(false)}
          onOk={() => sendToNetvisorHandler()}
          open={!!confirmOpen}
        />
      )}
      <div className={classes.Row}>
        {createInput(EFields.createdStart)}
        {createInput(EFields.createdEnd)}
      </div>
      {isAdmin && (
        <>
          <div className={classes.Row}>
            {createInput(EFields.statuses)}
            {createInput(EFields.users, {
              options: users,
            })}
          </div>
          <div className={classes.Row}>
            {createInput(EFields.type)}
          </div>
        </>
      )}

      <div className={classes.ButtonContainer}>
        <Button onClick={searchHandler} variant="contained">
          Hae
        </Button>
        {isAdmin && (
          <Button
            onClick={() => setConfirmOpen(true)}
            variant="contained"
            disabled={disableNetvisor}
            style={{ marginLeft: "1rem" }}
          >
            Lähetä Netvisoriin
          </Button>
        )}
      </div>
    </Container>
  );
};

export default EducationExpensesSearch;
