import { Button, LinearProgress, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React, { ReactNode, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import axios from '../../../axiosCF';
import Attachments from '../../../components/Attachments/Attachments';
import CustomDialog from '../../../components/UI/CustomDialog/CustomDialog';
import Input, { EInputType } from '../../../components/UI/Input/Input';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import { ESuekEducationStatus, ISuekEducation, ITargetGroup } from '../../../interfaces/domain';
import { IAppState } from '../../../interfaces/state';
import { defaultInputChangedHandler, getFormData, IControls, IInputConfigOptional, initForm } from '../../../shared/utility';
import * as actions from '../../../store/actions';

const classes = require("./SUEKEducation.module.scss");

interface IDisplayData {
  text: string;
  value: ReactNode;
}

enum EFields {
  participantsReality = "participantsReality",
  subscriberFeedback = "subscriberFeedback",
  participantsFeedback = "participantsFeedback",
  lecturerComments = "lecturerComments",
}

const initControls: IControls = {
  controls: {
    [EFields.participantsReality]: {
      type: EInputType.number,
      value: "",
      label: "Osallistujia (toteutunut)",
      valid: true,
    },
    [EFields.subscriberFeedback]: {
      type: EInputType.text,
      value: "",
      label: "Tilaajapalaute",
      multiline: true,
      valid: true,
    },
    [EFields.participantsFeedback]: {
      type: EInputType.text,
      value: "",
      label: "Osallistujan palaute",
      multiline: true,
      valid: true,
    },
    [EFields.lecturerComments]: {
      type: EInputType.text,
      value: "",
      label:
        "Luennoitsija kommentit (esim. kuvaus mitä kävit läpi koulutuksessa, onnistumiset/haasteet)",
      multiline: true,
      valid: true,
    },
  },
  isValid: false,
};

interface IStateProps {
  suekEducation: ISuekEducation | null;
  loading: boolean;
}

interface IDispatchProps {
  onGetSuekEducation: (id: number) => void;
  onGetSuekEducationClear: () => void;
  onUpdateSuekEducation: (education: ISuekEducation) => Promise<ISuekEducation>;
  onUploadSuekEducationFiles: (formData: FormData) => Promise<void>;
}

interface IMatch {
  id: string;
}

interface IProps
  extends IDispatchProps,
    IStateProps,
    RouteComponentProps<IMatch> {}

const SUEKEducation: React.FC<IProps> = ({
  onGetSuekEducation,
  onUpdateSuekEducation,
  suekEducation,
  loading,
  history,
  match,
  onUploadSuekEducationFiles,
  onGetSuekEducationClear,
}) => {
  const [targetGroupAmounts, setTargetGroupAmounts] = useState<ITargetGroup[]>(
    []
  );
  const [controls, setControls] = useState(initControls);
  const [isOpen, setOpen] = useState(false);
  const [doInit, setDoInit] = useState(true);

  const { id } = match.params;

  useEffect(() => {
    onGetSuekEducation(parseInt(id));
    return () => {
      onGetSuekEducationClear();
    };
  }, [onGetSuekEducation, id, onGetSuekEducationClear]);

  useEffect(() => {
    if (suekEducation && doInit) {
      const { status } = suekEducation;
      setControls(
        initForm(
          initControls.controls,
          suekEducation,
          status === ESuekEducationStatus.DONE
        )
      );
      setTargetGroupAmounts(suekEducation.targetGroups || []);
      setDoInit(false);
    }
    // eslint-disable-next-line
  }, [suekEducation]);

  const onSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    if (!suekEducation) return;
    const newEducation = getFormData(controls.controls) as ISuekEducation;
    newEducation.id = suekEducation.id;
    newEducation.targetGroups = targetGroupAmounts;

    const updatedSuekEducation = await onUpdateSuekEducation(newEducation);

    if (updatedSuekEducation && updatedSuekEducation.id) {
      setOpen(true);
    }
  };

  const onResetForm = () => {
    setControls(initControls);
  };

  const onCloseDialog = () => {
    history.push("/educations");
  };

  if (loading) {
    return <LinearProgress color="primary" />;
  }

  if (!suekEducation) return null;

  const createDisplayData = (displayData: IDisplayData[]) => {
    return (
      <Table size="small">
        <TableBody>
          {displayData.map((item) => (
            <TableRow key={item.text}>
              <TableCell>{item.text}:</TableCell>
              <TableCell>{item.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const onDropHandler = async (files: File[]) => {
    if (files) {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file_${index + 1}`, file);
      });
      formData.append("fileAmount", files.length.toString());
      formData.append("id", suekEducation.id.toString());
      onUploadSuekEducationFiles(formData);
    }
  };

  const {
    attachments,
    name,
    place,
    additionalInfo,
    theme,
    startDate,
    endDate,
    duration,
    sport,
    targetGroups,
    contactPerson,
    participantsEstimate,
    subscriber,
    status,
  } = suekEducation;

  const displayData = [
    { text: "Koulutuksen nimi", value: name },
    { text: "Paikka", value: place },
    { text: "Lisätiedot", value: additionalInfo },
    { text: "Teema", value: theme ? theme.join(", ") : "" },
    {
      text: "Ajankohta",
      value: `${startDate} ${endDate ? `- ${endDate}` : ""}`,
    },
    { text: "Kesto", value: duration },
    { text: "Laji", value: sport },
    {
      text: "Kohderyhmä",
      value: targetGroups.map((targetGroup) => targetGroup.name).join(", "),
    },
    { text: "Yhteyshenkilö", value: contactPerson },
    { text: "Osallistujia (arvioitu)", value: participantsEstimate },
    { text: "Tilaajataho", value: subscriber },
    { text: "Liitteet", value: <Attachments attachments={attachments} /> },
  ];

  const createInput = (inputName: EFields, config?: IInputConfigOptional) => {
    return (
      <Input
        formControl={{
          id: inputName,
          config: {
            ...controls.controls[inputName],
            ...config,
          },
        }}
        changed={(value, controlName: string) =>
          defaultInputChangedHandler(value, controlName, setControls)
        }
      />
    );
  };

  return (
    <React.Fragment>
      <CustomDialog
        title="Kiitos"
        content="Koulutuksen lähettäminen onnistui."
        buttonText="Sulje"
        onOk={onCloseDialog}
        open={isOpen}
      />

      <Typography variant="h5" gutterBottom>
        Koulutuksen tiedot
      </Typography>

      <Paper elevation={4}>{createDisplayData(displayData)}</Paper>
      <br />
      <Typography variant="h5" gutterBottom>
        Täytettävät tiedot
      </Typography>
      <Paper elevation={4} className={classes.Paper}>
        <form onSubmit={onSubmit}>
          {createInput(EFields.participantsReality)}
          {targetGroupAmounts.map((targetGroup) => (
            <TargetGroup
              key={targetGroup.id}
              targetGroup={targetGroup}
              onUpdate={(id: string, amount: number) => {
                setTargetGroupAmounts(prev => prev.map(prev => prev.id === id ? {...prev, amount } : prev));
              }}
              disabled={suekEducation.status === ESuekEducationStatus.DONE}
            />
          ))}

          {createInput(EFields.subscriberFeedback)}
          {createInput(EFields.participantsFeedback)}
          {createInput(EFields.lecturerComments)}

          {status === ESuekEducationStatus.OPEN ? (
            <React.Fragment>
              <p>
                Lataa käyttämäsi koulutusmateriaali ja osallistujalista, mikäli
                se oli sovittu kerättäväksi koulutuksessa
              </p>
              <Dropzone onDrop={onDropHandler}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                  const classNames = [classes.Dropzone];
                  if (isDragActive) {
                    classNames.push(classes.DropzoneDragActive);
                  }
                  return (
                    <div {...getRootProps()} className={classNames.join(" ")}>
                      <input {...getInputProps()} />
                      <p>
                        {isDragActive
                          ? "Tiedosto(t) ladataan kun päästät irti."
                          : "Lataa liitteet painamalla tästä tai pudottamalla tiedostot."}
                      </p>
                    </div>
                  );
                }}
              </Dropzone>
              <Button color="primary" className={classes.Button} type="submit">
                Lähetä
              </Button>
              <Button className={classes.Button} onClick={onResetForm}>
                Tyhjennä
              </Button>
            </React.Fragment>
          ) : (
            <Button
              color="primary"
              className={classes.Button}
              onClick={() => history.push("/educations")}
            >
              Takaisin
            </Button>
          )}
        </form>
      </Paper>
    </React.Fragment>
  );
};

interface ITargetGroupProps {
  targetGroup: ITargetGroup;
  onUpdate: (id: string, amount: number) => void;
  disabled: boolean;
}

const TargetGroup: React.FC<ITargetGroupProps> = ({
  targetGroup,
  onUpdate,
  disabled
}) => {
  return (
    <Input
      formControl={{
        id: "targetGroup_" + targetGroup.id,
        config: {
          type: EInputType.number,
          value: targetGroup.amount || "",
          label: `Osallistujia (${targetGroup.name})`,
          valid: true,
          disabled
        },
      }}
      changed={(value, controlName: string) =>
        onUpdate(targetGroup.id, value as number)
      }
    />
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.suekEducations.loading,
    suekEducation: state.suekEducations.suekEducation,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetSuekEducation: (id) => dispatch(actions.getSuekEducation(id)),
    onUpdateSuekEducation: (suekEducation: ISuekEducation) =>
      dispatch(actions.updateSuekEducation(suekEducation)),
    onUploadSuekEducationFiles: (formData) =>
      dispatch(actions.uploadSuekEducationFiles(formData)),
    onGetSuekEducationClear: () => dispatch(actions.getSuekEducationClear()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(SUEKEducation, axios));
